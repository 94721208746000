import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { coreConfig } from 'app/app-config';
import { fakeBackendProvider } from 'app/lib/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/lib/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { BasicAuthInterceptor } from './lib/helpers/basic-auth.interceptor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableResizerService } from './services/table-resizer.service';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { CountdownGlobalConfig } from 'ngx-countdown';
import { ComponentsModule } from './main/components/components.module';
import { BlockUIModule } from 'ng-block-ui';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './lib/helpers/timeout.interceptor';
import { FormsModule } from '@angular/forms';
import { NotFoundService } from '@core/services/not-found.service';


registerLocaleData(localeTr);

const appRoutes: Routes = [

    {
        path: '',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },

];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        HttpClientModule, NgxDatatableModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
}),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        //ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        NgSelectModule,
        ComponentsModule,
        BlockUIModule.forRoot(),
        FormsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 60000 },
        { provide: CountdownGlobalConfig },
        [DatePipe],
        [TableResizerService],
        // provider used to create fake backend, comment while using real api
        fakeBackendProvider,
        NotFoundService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        // Tarayıcı ortamındaysak Hammer.js'i yüklüyoruz
        if (isPlatformBrowser(this.platformId)) {
            import('hammerjs').then(() => {
                console.log('Hammer.js loaded in browser');
            });
        }
    }
}
