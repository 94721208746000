
<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')">
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>

<block-ui [template]="loadingTemplate">
</block-ui>
<!-- / theme customizer -->
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
